import { PageProps } from 'gatsby';
import { ExtractData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '~/components/List/Card/Extract';
import Item from '~/components/List/Item/Extract';
import List from '~/containers/Espace/List';
import extract from '~/params/extract.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';
import useAdminNotes from '~/utils/useAdminNotes';

const PageAdminExtracts: FC<
  PageProps & UserProps & { pageContext: { tab?: number } }
> = ({ location, pageContext: { tab }, user }) => {
  const { t } = useTranslation();
  const items = useAdminNotes({
    pathname: location.pathname,
  });

  // const handlePasteOnClick = async (
  //   event: SyntheticEvent<HTMLButtonElement>,
  // ) => {
  //   event.preventDefault();
  //
  //   const text = await navigator.clipboard.readText();
  //
  //   if (text) {
  //     try {
  //       const url = new URL(text);
  //       await toast.promise(
  //         Facia.call({
  //           kind: 'extract',
  //           params: {
  //             espaceId: espace.id,
  //           },
  //           q: url.href,
  //         }),
  //         {
  //           error: "Une erreur c'est produit lors de la création",
  //           pending: "En cours d'extraction de l'url",
  //           success: "L'extraction de l'url a réussi",
  //         },
  //       );
  //     } catch (error) {
  //       toast.error((error as Error).message);
  //     }
  //   }
  // };

  return (
    <TemplateAdmin>
      <List
        itemGrid={Card}
        itemList={Item}
        // menu={{
        //   right: [
        //     {
        //       icon: IconPaste,
        //       label: "Extraire l'URL qui se trouve dans le presse-papier",
        //       onClick: handlePasteOnClick,
        //     },
        //   ],
        // }}
        model={
          new ExtractData({
            params: extract,
          })
        }
        removeAddButton
        search={location.search}
        subMenu={items}
        tabIndex={tab ?? 0}
        title={t('notes.title')}
        user={user}
      />
    </TemplateAdmin>
  );
};

export default requireUser(PageAdminExtracts, 'admin');
